:root {
  --envname: #{$envname};
  --envtype: #{$envtype};
}

@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/mixins.scss';

@import './config/bootstrap-variables.scss';
// @import '~bootstrap/scss/variables.scss';
@import './config/bootstrap-vue-variables.scss';
// @import '~bootstrap-vue/src/variables.scss';

// Custom
@import './config/app-functions.scss';
@import './config/app-variables.scss';
@import './config/app-mixins.scss';
@import './config/identityserver.scss';
